import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const IntroSectionComponent = ({
  button,
  description,
  image,
  subtitle,
  textHtml,
  title,
  themeConfig,
}) => {
  return (
    <div
      className="IntroSectionComponent"
      style={
        themeConfig?.primaryBgColor?.hex ? { backgroundColor: themeConfig.primaryBgColor.hex } : {}
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="IntroSectionComponent__bg"
              style={{ backgroundImage: `url(${getImageUrl(image.path)})` }}
            />
          </div>
          <div className="col-lg-6">
            <div className="IntroSectionComponent__content">
              <h1>{title}</h1>
              <h5
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {subtitle}
              </h5>
              <h3>{description}</h3>
              <div dangerouslySetInnerHTML={{ __html: textHtml }}></div>
              <a
                className="button"
                href={button.link}
                style={
                  themeConfig?.accentColor?.hex
                    ? { backgroundColor: themeConfig.accentColor.hex }
                    : {}
                }
              >
                {button.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSectionComponent;
