import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const TwoColumnsType1Component = ({ leftColumn, pretitle, rightColumn, title, themeConfig }) => {
  return (
    <div className="TwoColumnsType1Component section-md">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
              {pretitle}
            </h5>
            <h2
              className="h1 mb-4"
              style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}
            >
              {title}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="TwoColumnsType1Component__content">
              <h3
                className="h3 mb-4"
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {leftColumn.description}
              </h3>
              <div dangerouslySetInnerHTML={{ __html: leftColumn.contentHtml }}></div>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="TwoColumnsType1Component__bg"
              style={
                themeConfig?.secondaryBgColor?.hex
                  ? { backgroundColor: themeConfig.secondaryBgColor.hex }
                  : {}
              }
            >
              <div
                className="TwoColumnsType1Component__bg__img"
                style={{ backgroundImage: `url(${getImageUrl(rightColumn.image.path)})` }}
              />
            </div>
            <div className="TwoColumnsType1Component__right-content">
              <div className="p-4">
                <ul className="TwoColumnsType1Component__right-content__checklist">
                  {rightColumn.checkList.map((item, index) => (
                    <li key={index}>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                    </li>
                  ))}
                </ul>
                <div className="TwoColumnsType1Component__right-content__button">
                  <hr />
                  <h3>{rightColumn.cta.title}</h3>
                  <a
                    href={rightColumn.cta.button.link}
                    className="button"
                    style={
                      themeConfig?.accentColor?.hex
                        ? { backgroundColor: themeConfig.accentColor.hex }
                        : {}
                    }
                  >
                    {rightColumn.cta.button.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnsType1Component;
