import React, { useState } from 'react';
import imageButtonPlay from '../../assets/images/button-play.png';

const VideoReviewComponent = ({
  pretitle,
  title,
  videoLink,
  iframeOptions = {},
  iframeStyles = {},
  themeConfig,
}) => {
  const [isShowingIframe, setShowingIframe] = useState(false);

  const parseYoutubeLink = () => {
    if (videoLink.includes('watch?v=')) {
      return {
        iframe: `https://www.youtube.com/embed/${videoLink.slice(
          videoLink.indexOf('?v=') + 3
        )}?controls=0&&autoplay=1`,
        thumbnail: `https://img.youtube.com/vi/${videoLink.slice(
          videoLink.indexOf('?v=') + 3
        )}/0.jpg`,
      };
    }
    if (videoLink.includes('youtu.be')) {
      return {
        iframe: `https://www.youtube.com/embed/${videoLink.slice(
          videoLink.indexOf('youtu.be/') + 9
        )}?controls=0&&autoplay=1`,
        thumbnail: `https://img.youtube.com/vi/${videoLink.slice(
          videoLink.indexOf('youtu.be/') + 9
        )}/0.jpg`,
      };
    }
  };

  return (
    <div
      className="VideoReviewComponent section-md"
      style={
        themeConfig?.secondaryBgColor?.hex
          ? { backgroundColor: themeConfig.secondaryBgColor.hex }
          : {}
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-md-9 mx-auto text-center">
            <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
              {pretitle}
            </h5>
            <h2 style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}>
              {title}
            </h2>

            <div className="video-review">
              {isShowingIframe ? (
                <div className="video-review__iframe">
                  <iframe
                    style={iframeStyles}
                    width="100%"
                    height={iframeOptions?.height || '100%'}
                    src={parseYoutubeLink().iframe}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="lazy"
                  />
                </div>
              ) : (
                <div className="video-review__image" onClick={() => setShowingIframe(true)}>
                  <div className="video-review__image__icon">
                    <img src={imageButtonPlay} alt="play icon" height="62" width="62" />
                  </div>

                  <img
                    src={parseYoutubeLink().thumbnail}
                    alt="thumbnail"
                    className="video-review__image__thumbnail w-100"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoReviewComponent;
