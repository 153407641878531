import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const BlockGallery = ({ pageTitle, repeater }) => {
  return (
    repeater.length > 0 && (
      <section className="wp-block-gallery columns-4 is-cropped">
        <ul className="blocks-gallery-grid">
          {repeater.map(({ image }, index) => (
            <li className="blocks-gallery-item" key={image._id}>
              <img
                src={getImageUrl(image.path, {
                  q: 50,
                  fm: 'webp',
                })}
                alt={pageTitle ? `${pageTitle}-${index}` : `image-${index}`}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </section>
    )
  );
};

export default BlockGallery;
