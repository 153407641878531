import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const TwoColumnsType2Component = ({ leftColumn, pretitle, rightColumn, title, themeConfig }) => {
  return (
    <div className="TwoColumnsType2Component section-md">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
              {pretitle}
            </h5>
            <h2
              className="h1 mb-4"
              style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}
            >
              {title}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="TwoColumnsType2Component__content">
              <h3
                className="h3 mb-4"
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {leftColumn.description}
              </h3>
              <div dangerouslySetInnerHTML={{ __html: leftColumn.contentHtml }} />
              <a
                href={leftColumn.button.link}
                className="button"
                style={
                  themeConfig?.accentColor?.hex
                    ? { backgroundColor: themeConfig.accentColor.hex }
                    : {}
                }
              >
                {leftColumn.button.text}
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <div className="TwoColumnsType2Component__bg">
              <div
                className="TwoColumnsType2Component__bg__img"
                style={{ backgroundImage: `url(${getImageUrl(rightColumn.image.path)})` }}
              />
            </div>
            <div className="TwoColumnsType2Component__right-content">
              <div dangerouslySetInnerHTML={{ __html: rightColumn.contentHtml }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnsType2Component;
