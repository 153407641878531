import React, { useState } from 'react';

import imageButtonPlay from '../../assets/images/button-play.png';

const VideoBlock = ({ youtubeLink, thumbnail, iframeOptions, iframeStyles = {} }) => {
  const [isShowingIframe, setShowingIframe] = useState(false);

  const parseYoutubeLink = () => {
    if (youtubeLink.includes('watch?v=')) {
      return {
        iframe: `https://www.youtube.com/embed/${youtubeLink.slice(
          youtubeLink.indexOf('?v=') + 3
        )}?controls=0&&autoplay=1`,
        thumbnail: `https://img.youtube.com/vi/${youtubeLink.slice(
          youtubeLink.indexOf('?v=') + 3
        )}/0.jpg`,
      };
    }
    if (youtubeLink.includes('youtu.be')) {
      return {
        iframe: `https://www.youtube.com/embed/${youtubeLink.slice(
          youtubeLink.indexOf('youtu.be/') + 9
        )}?controls=0&&autoplay=1`,
        thumbnail: `https://img.youtube.com/vi/${youtubeLink.slice(
          youtubeLink.indexOf('youtu.be/') + 9
        )}/0.jpg`,
      };
    }
  };

  return (
    youtubeLink && (
      <div className="c-video-block">
        {isShowingIframe ? (
          <div className="c-video-block__iframe">
            <iframe
              style={iframeStyles}
              width="100%"
              height={iframeOptions?.height || '100%'}
              src={parseYoutubeLink().iframe}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            />
          </div>
        ) : (
          <div className="c-video-block__image" onClick={() => setShowingIframe(true)}>
            <div className="c-video-block__image__icon">
              <img src={imageButtonPlay} alt="play icon" height="62" width="62" />
            </div>

            <img
              src={thumbnail || parseYoutubeLink().thumbnail}
              alt="thumbnail"
              className="c-video-block__image__thumbnail w-100"
            />
          </div>
        )}
      </div>
    )
  );
};

export default VideoBlock;
