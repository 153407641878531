import React from 'react';
import { getImageUrl } from '@takeshape/routing';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

const TrustedSectionComponent = ({
  contactBlock,
  ctaBlock,
  pretitle,
  reviews,
  title,
  themeConfig,
}) => {
  return (
    <div className="TrustedSectionComponent section-lg pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-11 col-lg-10 mx-auto">
            <div className="text-center">
              <h5
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {pretitle}
              </h5>
              <h2 style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}>
                {title}
              </h2>

              <div className="review-slider">
                <Slider
                  {...{
                    arrows: false,
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }}
                >
                  {reviews.map((item, index) => (
                    <div key={index}>
                      <blockquote>
                        <p>{item.quote}</p>
                      </blockquote>
                      <h5
                        style={
                          themeConfig?.accentColor?.hex
                            ? { color: themeConfig.accentColor.hex }
                            : {}
                        }
                      >
                        {item.author}
                      </h5>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div
              className="TrustedSectionComponent__contactBlock"
              style={
                themeConfig?.tertiaryBgColor?.hex
                  ? { backgroundColor: themeConfig.tertiaryBgColor.hex }
                  : {}
              }
            >
              <p>{contactBlock.text}</p>
              <a
                href={contactBlock.button.link}
                className="button"
                style={
                  themeConfig?.accentColor?.hex
                    ? { backgroundColor: themeConfig.accentColor.hex }
                    : {}
                }
              >
                {contactBlock.button.text}
              </a>
            </div>

            <div className="TrustedSectionComponent__ctaBlock">
              <div className="d-flex align-items-center">
                <div className="pr-2 pr-md-3">
                  <span className="icon-phone_in_talk" />
                </div>
                <div className="TrustedSectionComponent__ctaBlock__contacts">
                  <a href={ctaBlock.phoneLink.link}>{ctaBlock.phoneLink.text}</a>
                  <span>{ctaBlock.address}</span>
                </div>
              </div>
              <div>
                <img src={getImageUrl(ctaBlock.logo.path)} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedSectionComponent;
