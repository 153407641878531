import React from 'react';

const FaqComponent = ({ button, description, leftColumn, rightColumn, title, themeConfig }) => {
  return (
    <div className="FaqComponent section-md">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 text-center mx-auto">
            <h2
              className="h1 mb-4"
              style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}
            >
              {title}
            </h2>
            <h3
              className="h3 mb-5"
              style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
            >
              {description}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: leftColumn.contentHtml }}></div>
          </div>
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: rightColumn.contentHtml }}></div>
            <a
              href={button.link}
              className="button"
              style={
                themeConfig?.accentColor?.hex
                  ? { backgroundColor: themeConfig.accentColor.hex }
                  : {}
              }
            >
              {button.text}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
