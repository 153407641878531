import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import '../../assets/css/form-stack.css';

const BlockFormStack = ({ headingHtml, statementHtml, scriptCode, formId = 1 }) => {
  useEffect(() => {
    const fsEmbed = document.getElementsByClassName('fsBody fsEmbed')?.[formId - 1];
    if (fsEmbed) {
      // const newFsEmbed = fsEmbed.cloneNode(true);
      fsEmbed.style.display = 'block';
      document.getElementById(`formstack-form-${formId}`).appendChild(fsEmbed);
    }
  });

  return (
    <>
      <div
        id="book-a-call"
        className="wp-block-atomic-blocks-ab-column clinic-contact-form landing-page ab-block-layout-column"
        style={{ overflow: 'hidden' }}
      >
        <div className="formstack-wrapper ab-block-layout-column-inner ab-has-custom-background-color ab-has-custom-text-color">
          {ReactHtmlParser(headingHtml, {
            transform(domNode, index) {
              domNode.name = 'h2';
              return convertNodeToElement(domNode, index);
            },
          })}

          <div>{ReactHtmlParser(statementHtml)}</div>
          <div id={`formstack-form-${formId}`}>
            {scriptCode && (
              <Helmet>
                {ReactHtmlParser(scriptCode, {
                  transform(domNode) {
                    if (domNode.type === 'script') {
                      return (
                        <script key={0} type={domNode.attribs.type} src={domNode.attribs.src} />
                      );
                    }
                    if (domNode.name === 'noscript') {
                      return null;
                    }
                  },
                })}
              </Helmet>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockFormStack;
