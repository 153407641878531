import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const BlockDoctorCallout = ({ image, title, name }) => {
  return (
    <figure className="wp-block-image size-large">
      <img
        src={getImageUrl(image.path, {
          q: 50,
          fm: 'webp',
        })}
        alt={name}
        loading="lazy"
      />
      <div className="text-center">
        <strong>{name}</strong> <br />
        {title}
      </div>
    </figure>
  );
};

export default BlockDoctorCallout;
