import React from 'react';
import { getImageUrl } from '@takeshape/routing';
import VideoBlock from './VideoBlock';

const BlockImageAndVideoGallery = ({ pageTitle, gallery }) => {
  return (
    gallery.length > 0 && (
      <section className="wp-block-gallery columns-3 is-cropped">
        <ul className="blocks-gallery-grid">
          {gallery.map(({ __typename, image, youtubeLink }, index) => (
            <li className="blocks-gallery-item" key={index}>
              {__typename === 'TS_ImageBlock' && (
                <img
                  src={getImageUrl(image.path, {
                    q: 50,
                    fm: 'webp',
                  })}
                  alt={pageTitle ? `${pageTitle}-${index}` : `image-${index}`}
                  loading="lazy"
                />
              )}
              {__typename === 'TS_YoutubeVideoBlock' && youtubeLink && (
                <VideoBlock youtubeLink={youtubeLink} />
              )}
            </li>
          ))}
        </ul>
      </section>
    )
  );
};

const BlockVideoGallery = ({ repeater }) => {
  return (
    <section className="wp-block-gallery columns-3 is-cropped">
      <ul className="blocks-gallery-grid">
        {repeater.map(({ youtubeLink }, index) => (
          <li className="blocks-gallery-item" key={index}>
            {youtubeLink && <VideoBlock youtubeLink={youtubeLink} />}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BlockImageAndVideoGallery;
