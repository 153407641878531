import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import BlockBodyContent from '../components/Blocks/BlockBodyContent';
import BlockGallery from '../components/Blocks/BlockGallery';
import BlockVideoGallery from '../components/Blocks/BlockVideoGallery';
import BlockHelloBar from '../components/Blocks/BlockHelloBar';
import BlockHero from '../components/Blocks/BlockHero';
import BlockTypeformWidget from '../components/Blocks/BlockTypeformWidget';
import BlockBodyContentCenter from '../components/Blocks/BlockBodyContentCenter';
import BlockImageAndVideoGallery from '../components/Blocks/BlockImageAndVideoGallery';
import BlockFormStack from '../components/Blocks/BlockFormStack';

import LayoutV2 from '../components/v2/Layout';
import ContactSectionComponent from '../components/v2/ContactSectionComponent';
import FaqComponent from '../components/v2/FaqComponent';
import FooterComponent from '../components/v2/FooterComponent';
import HeaderComponent from '../components/v2/HeaderComponent';
import IntroSectionComponent from '../components/v2/IntroSectionComponent';
import SimpleTwoColumnsComponent from '../components/v2/SimpleTwoColumnsComponent';
import SingleColumnComponent from '../components/v2/SingleColumnComponent';
import SingleColumnWithBgComponent from '../components/v2/SingleColumnWithBgComponent';
import TrustedSectionComponent from '../components/v2/TrustedSectionComponent';
import TwoColumnsType1Component from '../components/v2/TwoColumnsType1Component';
import TwoColumnsType2Component from '../components/v2/TwoColumnsType2Component';
import VideoReviewComponent from '../components/v2/VideoReviewComponent';

const getComponent = (typename, blockIndex, blockProps, allProps) => {
  switch (typename) {
    case 'TS_HeroBlock':
      return <BlockHero key={blockIndex} {...blockProps} pageTitle={allProps.title} />;
    case 'TS_VideoGallery':
      return <BlockVideoGallery key={blockIndex} {...blockProps} />;
    case 'TS_ImageGallery':
      return <BlockGallery key={blockIndex} {...blockProps} pageTitle={allProps.title} />;
    case 'TS_ImageAndVideoGallery':
      return (
        <BlockImageAndVideoGallery key={blockIndex} {...blockProps} pageTitle={allProps.title} />
      );
    case 'TS_ContentCenterBlock':
      return <BlockBodyContentCenter key={blockIndex} {...blockProps} />;
    case 'TS_ContentBlock':
      return <BlockBodyContent key={blockIndex} {...blockProps} />;

    default:
      return null;
  }
};

const getComponentV2 = (typename, blockIndex, blockProps, allProps) => {
  const { themeConfig } = allProps;
  switch (typename) {
    case 'TS_HeaderComponent':
      return <HeaderComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_IntroSectionComponent':
      return <IntroSectionComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_SingleColumnComponent':
      return <SingleColumnComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_SimpleTwoColumnsComponent':
      return (
        <SimpleTwoColumnsComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />
      );
    case 'TS_SingleColumnWithBgComponent':
      return (
        <SingleColumnWithBgComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />
      );
    case 'TS_FaqComponent':
      return <FaqComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_TwoColumnsType1Component':
      return (
        <TwoColumnsType1Component key={blockIndex} themeConfig={themeConfig} {...blockProps} />
      );
    case 'TS_TwoColumnsType2Component':
      return (
        <TwoColumnsType2Component key={blockIndex} themeConfig={themeConfig} {...blockProps} />
      );
    case 'TS_VideoReviewComponent':
      return <VideoReviewComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_ContactSectionComponent':
      return <ContactSectionComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_TrustedSectionComponent':
      return <TrustedSectionComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;
    case 'TS_FooterComponent':
      return <FooterComponent key={blockIndex} themeConfig={themeConfig} {...blockProps} />;

    default:
      return null;
  }
};

const IndexPage = ({
  data: {
    takeshape: { getLandingPage },
  },
}) => {
  const blockHeroData = getLandingPage?.content?.find((item) => item.__typename === 'TS_HeroBlock');

  return (
    <>
      {getLandingPage?.content?.length > 0 && (
        <Layout
          title={getLandingPage.titleTag}
          description={getLandingPage.metaDescription}
          seo={getLandingPage.seo}
        >
          <BlockHelloBar {...getLandingPage.floatingCta} />
          <div id="primary">
            <main id="main" className="container">
              <div className="entry-content">
                <div className="row">
                  <article className="entry-content-outer col-12 pos-rel">
                    <div className="entry-inner page type-page status-publish hentry">
                      {getLandingPage.title && (
                        <h1 className="entry-title">{getLandingPage.title}</h1>
                      )}
                      <div className="entry-inner-content">
                        {getLandingPage.content.map((block, blockIndex) => {
                          const { __typename, ...props } = block;
                          return getComponent(__typename, blockIndex, props, getLandingPage);
                        })}
                        {/* 
                    <BlockGallery
                      pageTitle={getLandingPageSettings.pageTitle}
                      {...getLandingPageSettings.gallery}
                    />
                    <BlockBrandPromise {...getLandingPageSettings.brandPromise} />
                    <hr className="wp-block-separator" />
                    <BlockBodyContent bodyContentHtml={getLandingPageSettings.bodyContentHtml} />
                    <section className="wp-block-gallery columns-2 is-cropped videos-section">
                      <ul className="blocks-gallery-grid">
                        {getLandingPageSettings.videos.map(({ videoUrl }, index) => (
                          <li className="blocks-gallery-item" key={index}>
                            <VideoBlock youtubeLink={videoUrl} />
                          </li>
                        ))}
                      </ul>
                    </section>
                    <BlockBodyContent
                      bodyContentHtml={getLandingPageSettings.bodyContentOurApproachHtml}
                    /> */}
                        <BlockTypeformWidget {...getLandingPage.footerForm} />
                        {blockHeroData?.form && (
                          <div className="text-center my-4">
                            <Link to="#book-a-call" className="btn btn--yellow btn--radius-sm">
                              Book a Call
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </main>
          </div>
        </Layout>
      )}
      {getLandingPage?.contentNew?.length > 0 && (
        <LayoutV2
          title={getLandingPage.titleTag}
          description={getLandingPage.metaDescription}
          seo={getLandingPage.seo}
          themeConfig={getLandingPage.themeConfig}
        >
          <main>
            {getLandingPage.contentNew.map((block, blockIndex) => {
              const { __typename, ...props } = block;
              return getComponentV2(__typename, blockIndex, props, getLandingPage);
            })}
          </main>
        </LayoutV2>
      )}
    </>
  );
};

export const query = graphql`
  query LandingPage($landingID: ID!) {
    takeshape {
      getLandingPage(_id: $landingID) {
        content {
          ... on TS_ContentCenterBlock {
            __typename
            contentSection {
              contentHtml
              sectionTitle
            }
          }
          ... on TS_ContentBlock {
            __typename
            contentSection {
              contentHtml
              secondColumn {
                ... on TS_DoctorCallout {
                  __typename
                  image {
                    path
                  }
                  name
                  shortBio
                  title
                }
                ... on TS_QuoteSection {
                  __typename
                  image {
                    path
                  }
                  name
                  quoteHtml
                }
                ... on TS_YoutubeVideoBlock {
                  __typename
                  youtubeLink
                }
              }
              sectionTitle
            }
          }
          ... on TS_HeroBlock {
            __typename
            form {
              headingHtml
              scriptCode
              statementHtml
            }
            heroImage {
              path
            }
          }
          ... on TS_ImageGallery {
            __typename
            repeater {
              image {
                _id
                path
              }
            }
          }
          ... on TS_QuoteSection {
            __typename
            image {
              path
            }
            name
            quote
          }
          ... on TS_VideoGallery {
            __typename
            repeater {
              youtubeLink
            }
          }
          ... on TS_ImageAndVideoGallery {
            __typename
            gallery {
              ... on TS_ImageBlock {
                __typename
                image {
                  _id
                  path
                }
              }
              ... on TS_YoutubeVideoBlock {
                __typename
                youtubeLink
              }
            }
          }
        }
        contentNew {
          ... on TS_ContactSectionComponent {
            __typename
            leftColumn {
              contentHtml
              pretitle
              title
            }
          }
          ... on TS_FaqComponent {
            __typename
            button {
              link
              text
            }
            description
            leftColumn {
              contentHtml
            }
            rightColumn {
              contentHtml
            }
            title
          }
          ... on TS_FooterComponent {
            __typename
            contentHtml
          }
          ... on TS_HeaderComponent {
            __typename
            address
            button {
              link
              text
            }
            callUsLink {
              link
              text
            }
            logo {
              path
            }
          }
          ... on TS_IntroSectionComponent {
            __typename
            button {
              link
              text
            }
            description
            image {
              path
            }
            subtitle
            textHtml
            title
          }
          ... on TS_SimpleTwoColumnsComponent {
            __typename
            button {
              link
              text
            }
            description
            image {
              path
            }
            pretitle
            textHtml
            title
          }
          ... on TS_SingleColumnComponent {
            __typename
            contentHtml
            pretitle
            title
          }
          ... on TS_SingleColumnWithBgComponent {
            __typename
            backgroundImage {
              path
            }
            pretitle
            textHtml
            title
          }
          ... on TS_TrustedSectionComponent {
            __typename
            contactBlock {
              button {
                link
                text
              }
              text
            }
            ctaBlock {
              address
              logo {
                path
              }
              phoneLink {
                link
                text
              }
            }
            pretitle
            reviews {
              author
              quote
            }
            title
          }
          ... on TS_TwoColumnsType1Component {
            __typename
            leftColumn {
              contentHtml
              description
            }
            pretitle
            rightColumn {
              checkList {
                text
                title
              }
              cta {
                button {
                  link
                  text
                }
                title
              }
              image {
                path
              }
            }
            title
          }
          ... on TS_TwoColumnsType2Component {
            __typename
            leftColumn {
              button {
                link
                text
              }
              contentHtml
              description
            }
            pretitle
            rightColumn {
              image {
                path
              }
              contentHtml
            }
            title
          }
          ... on TS_VideoReviewComponent {
            __typename
            pretitle
            title
            videoLink
          }
        }
        themeConfig {
          accentColor {
            hex
          }
          primaryBgColor {
            hex
          }
          secondaryBgColor {
            hex
          }
          tertiaryBgColor {
            hex
          }
          textColor {
            hex
          }
          titleColor {
            hex
          }
        }
        floatingCta {
          button {
            link
            text
          }
          textHtml
        }
        footerForm {
          embedCode
          embedTag
        }
        metaDescription
        title
        titleTag
        seo {
          headScripts {
            embedCode
          }
        }
      }
    }
  }
`;

export default IndexPage;
