import React from 'react';

const ContactSectionComponent = ({ leftColumn, themeConfig }) => {
  return (
    <div className="ContactSectionComponent section-md">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
              {leftColumn.pretitle}
            </h5>
            <h2 className="h1">{leftColumn.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: leftColumn.contentHtml }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSectionComponent;
