import React from 'react';

import VideoBlock from './VideoBlock';

const BlockVideoGallery = ({ repeater }) => {
  return (
    <section className="wp-block-gallery columns-3 is-cropped">
      <ul className="blocks-gallery-grid">
        {repeater.map(({ youtubeLink }, index) => (
          <li className="blocks-gallery-item" key={index}>
            {youtubeLink && <VideoBlock youtubeLink={youtubeLink} />}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BlockVideoGallery;
