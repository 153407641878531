import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const SimpleTwoColumnsComponent = ({
  button,
  description,
  image,
  pretitle,
  textHtml,
  title,
  themeConfig,
}) => {
  return (
    <div className="SimpleTwoColumnsComponent">
      <div className="container">
        <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
          {pretitle}
        </h5>
        <h2
          className="h1"
          style={themeConfig?.titleColor?.hex ? { color: themeConfig.titleColor.hex } : {}}
        >
          {title}
        </h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="SimpleTwoColumnsComponent__content">
              <h3
                className="h3"
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {description}
              </h3>
              <div dangerouslySetInnerHTML={{ __html: textHtml }} />
              <a
                className="button"
                href={button.link}
                style={
                  themeConfig?.accentColor?.hex
                    ? { backgroundColor: themeConfig.accentColor.hex }
                    : {}
                }
              >
                {button.text}
              </a>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="SimpleTwoColumnsComponent__bg">
              <div
                className="SimpleTwoColumnsComponent__bg__img"
                style={{ backgroundImage: `url(${getImageUrl(image.path)})` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTwoColumnsComponent;
