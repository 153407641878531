import React from 'react';

const FooterComponent = ({ contentHtml, themeConfig }) => {
  return (
    <div
      className="FooterComponent"
      style={
        themeConfig?.tertiaryBgColor?.hex
          ? { backgroundColor: themeConfig.tertiaryBgColor.hex }
          : {}
      }
    >
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </div>
    </div>
  );
};

export default FooterComponent;
