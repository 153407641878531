import React from 'react';
import { getImageUrl } from '@takeshape/routing';

import BlockFormStack from './BlockFormStack';

const BlockHero = ({ pageTitle, heroImage, form }) => {
  return (
    <>
      <div className="wp-block-atomic-blocks-ab-columns collapse-tablet ab-layout-columns-2 ab-2-col-equal">
        <div
          className="ab-layout-column-wrap ab-block-layout-column-gap-1 ab-is-responsive-column"
          style={{ marginBottom: '1em' }}
        >
          {heroImage.path && (
            <section className="wp-block-atomic-blocks-ab-column ab-block-layout-column hero-block-image">
              <img
                src={getImageUrl(heroImage.path, {
                  q: 50,
                  fm: 'webp',
                })}
                alt={pageTitle || ''}
                loading="lazy"
              />
            </section>
          )}
          {form && <BlockFormStack {...form} formId={1} />}
        </div>
      </div>
    </>
  );
};

export default BlockHero;
