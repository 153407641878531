import React from 'react';

const SingleColumnComponent = ({ title, pretitle, contentHtml, themeConfig }) => {
  return (
    <div className="SingleColumnComponent section-lg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <h5 style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}>
              {pretitle}
            </h5>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleColumnComponent;
