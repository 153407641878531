import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const SingleColumnWithBgComponent = ({
  backgroundImage,
  pretitle,
  textHtml,
  title,
  themeConfig,
}) => {
  return (
    <div
      className="SingleColumnWithBgComponent section-lg"
      style={{ backgroundImage: `url(${getImageUrl(backgroundImage.path)})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-11 col-lg-8 mx-auto">
            <div className="text-center">
              <h5
                style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
              >
                {pretitle}
              </h5>
              <h2>{title}</h2>
            </div>

            <div
              className="SingleColumnWithBgComponent__text"
              dangerouslySetInnerHTML={{ __html: textHtml }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleColumnWithBgComponent;
