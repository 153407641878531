import React from 'react';

import BlockDoctorCallout from './BlockDoctorCallout';
import VideoBlock from './VideoBlock';
import BlockQuote from './BlockQuote';

const getComponent = (typename, blockIndex, blockProps) => {
  switch (typename) {
    case 'TS_DoctorCallout':
      return <BlockDoctorCallout key={blockIndex} {...blockProps} />;
    case 'TS_QuoteSection':
      return <BlockQuote key={blockIndex} {...blockProps} />;
    case 'TS_YoutubeVideoBlock':
      return (
        <div className="wp-block-embed" key={blockIndex}>
          <VideoBlock {...blockProps} />
        </div>
      );

    default:
      return null;
  }
};

const BlockBodyContent = ({ contentSection: { sectionTitle, contentHtml, secondColumn } }) => (
  <section className="body-content">
    {!secondColumn?.length ? (
      <>
        <h2>{sectionTitle}</h2>
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </>
    ) : (
      <>
        <h2>{sectionTitle}</h2>
        <div className="wp-block-columns">
          <div className="wp-block-column" style={{ flexBasis: '66.66%' }}>
            <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
          </div>
          <div className="wp-block-column" style={{ flexBasis: '33.33%' }}>
            {secondColumn.map((block, blockIndex) => {
              const { __typename, ...props } = block;
              return getComponent(__typename, blockIndex, props);
            })}
          </div>
        </div>
      </>
    )}

    <hr className="wp-block-separator" />
  </section>
);

export default BlockBodyContent;
