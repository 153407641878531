import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const BlockQuote = ({ quoteHtml, image, name }) => {
  return (
    <div className="wp-block-atomic-blocks-ab-testimonial left-aligned ab-has-avatar ab-font-size-18 ab-block-testimonial">
      <div
        className="ab-testimonial-text"
        style={{ color: '#32373c' }}
        dangerouslySetInnerHTML={{ __html: quoteHtml }}
      />
      <div className="ab-testimonial-info">
        <div className="ab-testimonial-avatar-wrap">
          <div className="ab-testimonial-image-wrap">
            <img
              src={getImageUrl(image.path, {
                q: 50,
                fm: 'webp',
              })}
              alt={name}
              loading="lazy"
            />
          </div>
        </div>
        <h2 className="ab-testimonial-name">
          <br />
          {name}
        </h2>
      </div>
    </div>
  );
};

export default BlockQuote;
