import React from 'react';
import SEO from '../SEO';

const Layout = ({ children, title, description, seo, themeConfig }) => {
  return (
    <>
      <SEO title={title} description={description} seo={seo} />
      <div className="layout-v2" style={{ color: themeConfig?.textColor?.hex || '#4a4a4a' }}>
        {children}
      </div>
    </>
  );
};

export default Layout;
