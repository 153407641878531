import React from 'react';

const BlockBodyContentCenter = ({ contentSection: { sectionTitle, contentHtml } }) => {
  return (
    <div className="text-center">
      <h3 style={{ fontSize: '32px' }}>{sectionTitle}</h3>
      <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      <hr className="wp-block-separator" />
    </div>
  );
};

export default BlockBodyContentCenter;
