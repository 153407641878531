import React from 'react';
import { getImageUrl } from '@takeshape/routing';

const HeaderComponent = ({ logo, address, button, callUsLink, themeConfig }) => {
  return (
    <div className="HeaderComponent">
      <div className="container">
        <div className="text-end">
          <span
            className="icon-location"
            style={themeConfig?.accentColor?.hex ? { color: themeConfig.accentColor.hex } : {}}
          />
          <span> {address}</span>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <a href="/">
              <img src={getImageUrl(logo.path)} alt="logo" />
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <a className="HeaderComponent__call" href={callUsLink.link}>
              <span className="icon-phone_in_talk" /> {callUsLink.text}
            </a>
            <a className="HeaderComponent__call--mobile" href={callUsLink.link}>
              <span className="icon-phone_in_talk" />
            </a>
            <a
              className="button"
              href={button.link}
              style={
                themeConfig?.accentColor?.hex
                  ? { backgroundColor: themeConfig.accentColor.hex }
                  : {}
              }
            >
              {button.text}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
